@import '../../styles/core';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.form {
  max-width: 700px;
  width: 100%;
  margin: auto auto;

  hr {
    width: 90%;
    border-bottom: 1px solid $gray;
    margin: 20px 0;

    &:last-of-type {
      border-bottom: none;
      margin: 0;
    }
  }
}

.forms {
  display: flex;
  flex-direction: column;
}

.fighterGroup {
  width: 100%;
  display: flex;
  align-items: flex-end;

  .longInput {
    width: 90%;

    div {
      width: 100%;
    }

    input,
    select {
      text-transform: capitalize;

      width: 100%;
    }
  }

  button {
    margin-left: 5px;
  }
}

.or {
  width: 90%;
  border-bottom: transparentize($blue, 0.7) dashed 2px;
  position: relative;
  margin: 20px 0 0;

  &::before {
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 0.04rem;
    color: transparentize($blue, 0.4);
    content: 'OR';
    position: absolute;
    left: 47%;
    top: -6px;
    background-color: #fff;
    padding: 0 10px;
  }

  @include mobile {
    width: 100%;
  }
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;

  border: 2px dashed $blue;
  border-radius: 5px;
  background-color: $white;
  outline: 0;

  @include mobile {
    margin-top: 30px;
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 100%;
    outline: 0;
    cursor: pointer;

    @include mobile {
      max-width: 90%;
    }
  }

  svg {
    color: $gray;
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }

  p {
    color: $gray;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 0.03rem;

    text-align: center;
  }
}
