@import '../../styles/core';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.form {
  max-width: 700px;
  width: 100%;
  margin: auto auto;
}

.message {
  font-size: 1.2rem;
  font-weight: 600;

  margin: 20px 0;

  &.green {
    color: $green;
  }

  &.red {
    color: $red;
  }
}
