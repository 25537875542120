$mobile: 576px;
$tablet: 768px;
$desktop: 1024px;
$large-desktop: 1200px;

@mixin mobile {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) and (max-width: #{$desktop - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}) and (max-width: #{$large-desktop - 1px}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop}) {
    @content;
  }
}

// Portrait mobile and below
@mixin max-portrait-mobile {
  @media (max-width: #{$mobile - 1px}) {
    @content;
  }
}

// Landscape mobile and below
@mixin max-landscape-mobile {
  @media (max-width: #{$tablet - 1px}) {
    @content;
  }
}

// Tablet and below
@mixin max-tablet {
  @media (max-width: #{$desktop - 1px}) {
    @content;
  }
}

// Desktop and below
@mixin max-desktop {
  @media (max-width: #{$large-desktop - 1px}) {
    @content;
  }
}
