@import '../styles/core';

$border-radius: 6px;

.row {
  @include mobile {
    margin: 0 1rem;
  }
}

.form {
  position: sticky;
  top: 0;
  border-right: 1px solid transparentize($gray, 0.9);
  margin: -40px 0;
  padding: 40px 20px 40px 0;

  @include mobile {
    position: initial;
    margin: 0;
    padding: 0;
    border: 0;
  }

  h2 {
    margin: 0;
    padding-bottom: 20px;
  }

  .clear {
    display: flex;
    font-size: 0.65rem;
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
    align-items: center;

    color: $red;

    svg {
      margin-top: -1px;
      width: 14px;
      margin-right: 3px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .advancedLink {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1.1rem;
    font-weight: 500;
    color: $gray;
    align-content: center;
    justify-content: flex-start;

    svg {
      height: 15px;
      width: 15px;
      margin-right: 3px;
      margin-left: 5px;
    }
  }
}

.results {
  .top {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 20px;

    @include mobile {
      margin-top: 40px;
    }

    a {
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      background-color: $red;
      border-radius: 3px;
      color: $white;
      padding: 7px 15px;
      font-size: 0.7rem;
      letter-spacing: 0.02rem;
      margin-left: auto;

      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

      &:hover {
        text-decoration: none;
        background-color: darken($red, 15%);
      }
    }
  }

  margin-left: 30px;

  @include mobile {
    margin: 0;
  }

  h2 {
    margin: 0;
  }

  .notfound {
    display: flex;
    flex-direction: column;
    color: $blue;

    max-height: 250px;
    min-height: 250px;
    align-items: center;
    justify-content: center;
    margin: auto auto;

    span {
      color: $black;
      margin-top: 30px;
      font-size: 1.2rem;
    }

    svg {
      height: 50px;
      width: 50px;
    }
  }
}

.pagination {
  display: flex;

  button {
    display: flex;
    cursor: pointer;
    padding: 0.4rem 0.55rem;

    font-size: 0.9rem;
    border: 1px solid transparentize($blue, 0.7);
    background-color: transparentize($blue, 0.9);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &.disabled {
      background-color: transparent !important;
      pointer-events: none;
      opacity: 0.6;
      border: none;
    }

    &:hover {
      background-color: transparentize($blue, 0.7);
    }

    &.next {
      margin-left: auto;

      svg {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    svg {
      height: 11px;
      width: 11px;
      margin-right: 5px;
    }
  }
}
