@import '../styles/core';

.confirm {
  text-align: center;
  width: 500px;
  padding: 40px;
  background: $blue;
  box-shadow: 0 20px 75px transparentize($blue, 0.23);
  color: $white;
}

.confirm > h1 {
  margin-top: 0;
}

.confirm > button {
  width: 160px;
  padding: 10px;
  border: 1px solid $white;
  margin: 10px;
  cursor: pointer;
  background: none;
  color: $white;
  font-size: 14px;
}
