@import '../styles/core';

$border-radius: 6px;

.responsiveTable tbody tr {
  @include mobile {
    border: 0;
    border-bottom: 1px solid $blue;

    &:last-of-type {
      border: 0;
    }
  }
}

.status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @include mobile {
    svg {
      font-size: 1.2rem;
    }
  }
}

table {
  @include mobile {
    border: 0;
  }

  font-size: 0.8rem;

  th {
    white-space: nowrap;
    color: $gray;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    font-size: 0.8rem;
    text-align: left;
  }

  tr {
    @include mobile {
      border: 0;
      border-bottom: 1px solid $blue;

      &:last-of-type {
        border: 0;
      }
    }

    td > div {
      @include mobile {
        left: 0 !important;
      }
    }

    td {
      @include mobile {
        padding-top: 0 !important;
        padding-bottom: 5px !important;
        white-space: normal !important;
      }
    }
  }

  svg {
    color: $blue;
    height: 14px;
    width: 14px;
    margin-bottom: -1px;
    margin-right: 1px;
    path {
      stroke-width: 2px !important;
    }
  }
}

.stats {
  display: flex;

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 6px;
    color: $white;
  }

  .green {
    background-color: $green;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .red {
    background-color: $red;
  }

  .blue {
    background-color: $blue;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .number {
    font-weight: 600;
    font-size: 0.9rem;
  }
}
