@import '../styles/core';

.main {
  margin-top: 20px;
  padding-bottom: 65px;

  @include mobile {
    padding-bottom: 100px;
  }
}
