@import '../styles/colors';

@keyframes rotate-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  display: flex;
  max-height: 350px;
  min-height: 350px;
  align-items: center;
  justify-content: center;
  margin: auto auto;

  span {
    color: $black;

    svg {
      height: 30px;
      width: 30px;
      animation: rotate-loader 1s linear infinite;
    }
  }
}
