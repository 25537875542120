@import '../styles/core';

$border-radius: 6px;

.row {
  @include mobile {
    margin: 0 1rem;
  }

  .advancedLink {
    display: flex;
    margin-top: 20px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 1.1rem;
    font-weight: 500;
    color: $gray;
    align-content: center;
    justify-content: flex-start;

    svg {
      height: 15px;
      width: 15px;
      margin-right: 3px;
      margin-left: 5px;
    }
  }
}

.event {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  border-right: 1px solid transparentize($gray, 0.9);
  margin: -40px 0;
  padding: 40px 20px 40px 0;

  .field {
    font-size: 0.95rem;
    font-weight: 400;
    color: #0d0106;
    margin-top: 10px;
  }
}

.fieldLegend {
  font-size: 0.75rem;
  font-weight: 400;
  color: #0d0106;
  margin-top: 10px;
}

.cell {
  @include mobile {
    padding: 4px !important;
    min-height: 32px;
  }
}

.results {
  .days {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 25px;
    h2 {
      font-size: 1.1rem;
    }
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      margin-top: 40px;
    }
    .topAdmin {
      display: flex;
      flex-direction: row;
      .adminLink {
        margin-left: 4px;
      }
    }
    a {
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      background-color: $red;
      border-radius: 3px;
      color: $white;
      padding: 7px 15px;
      font-size: 0.7rem;
      letter-spacing: 0.02rem;
      margin-left: 4px;

      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

      &:hover {
        text-decoration: none;
        background-color: darken($red, 15%);
      }
    }
  }

  margin-left: 30px;

  @include mobile {
    margin: 0;
  }

  h2 {
    margin: 0;
  }

  .notfound {
    display: flex;
    flex-direction: column;
    color: $blue;

    max-height: 250px;
    min-height: 250px;
    align-items: center;
    justify-content: center;
    margin: auto auto;

    span {
      color: $black;
      margin-top: 30px;
      font-size: 1.2rem;
    }

    svg {
      height: 50px;
      width: 50px;
    }
  }

  table {
    margin: 0;

    .outcome {
      display: none;

      @media screen and (max-width: 40em) {
        display: initial;
        padding: 2px 5px;
        margin-left: 0.2rem;
      }
    }

    th {
      color: $gray;
      text-transform: uppercase;
      letter-spacing: 0.02rem;
      font-size: 0.8rem;
      text-align: left;
    }

    tr {
      @include mobile {
        border: 0;
        border-bottom: 1px solid $blue;

        &:last-of-type {
          border: 0;
        }
      }

      td > div {
        @include mobile {
          left: 0 !important;
        }
      }
    }

    .admin {
      button {
        border: 1px solid $blue;
        border-radius: $border-radius;
        padding: 2px 3px;
        color: $red;
        cursor: pointer;

        &:first-of-type {
          margin-right: 0.3rem;
        }

        svg {
          margin: 0 !important;
        }
      }
    }

    svg {
      color: $blue;
      height: 14px;
      width: 14px;
      margin-bottom: -1px;
      margin-right: 1px;
      path {
        stroke-width: 2px !important;
      }
    }
  }

  .badge {
    background-color: transparent;
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
    border-radius: 2px;
    padding: 3px 6px;
    color: $white;
    font-weight: 500;

    &.win {
      background-color: $green;
    }

    &.loss {
      background-color: $red;
    }

    &.draw {
      background-color: #7b9ea8;
    }

    &.exh {
      background-color: $white;
      color: #7b9ea8;
      border-color: #7b9ea8;
    }
  }

  .resultsAtTheTime {
    display: flex;

    .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 6px;
      color: $white;
    }

    .green {
      background-color: $green;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    .red {
      background-color: $red;
    }

    .blue {
      background-color: $blue;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .number {
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
}
