@import '../styles/core';

@keyframes rotate-loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.groupCheckbox {
  margin-top: 12px;
}

.groupRadio {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}
.groupSlider {
  margin-top: 12px;
  display: flex;
  flex-direction: column;
}

.groupSelect {
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  > div {
    display: block;

    > div {
      display: flex;
    }
  }

  @include mobile {
    .select {
      width: 100%;
    }
  }
}

.groupDate {
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  > div {
    display: block;

    > div {
      display: flex;
    }
  }

  @include mobile {
    input {
      width: 100%;
    }
  }
}

.group {
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  &.noMargin {
    margin-top: 0;
  }

  input:not([type='radio']),
  textarea {
    -webkit-appearance: none;
    overflow: hidden;
  }

  &.withError {
    input {
      border: 1px solid transparentize($red, 0.7);
    }
  }

  button {
    display: flex;
    cursor: pointer;
    padding: 0.4rem 0.55rem;
    border-radius: 3px;

    font-size: 0.9rem;
    border: 1px solid transparentize($blue, 0.7);
    background-color: transparentize($blue, 0.9);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: transparentize($blue, 0.7);
    }

    svg {
      height: 15px;
      width: 15px;
      margin-right: 3px;
    }

    .spinner {
      color: $black;

      svg {
        height: 15px;
        width: 15px;
        animation: rotate-loader 1s linear infinite;
      }
    }
  }

  &.loading {
    button {
      cursor: not-allowed;
    }
  }

  &.primary {
    button {
      color: #fff;
      border: 1px solid transparentize($red, 0.5);
      background-color: transparentize($red, 0.5);

      &:hover {
        border: 1px solid transparentize($red, 0.3);
        background-color: transparentize($red, 0.3);
      }
    }
  }

  @include mobile {
    input,
    button,
    textarea {
      width: 100%;
    }
  }
}
