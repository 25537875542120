@import '../styles/core';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorMessage {
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  margin-top: 2rem;
  color: $red;
  font-weight: 500;
}

.form {
  width: 500px;
  margin: auto auto;
}
