@import '../styles/core';

.footerContainer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #262626;
  color: $white;
  box-shadow: 0 0 9px 3px rgba(41, 41, 41, 0.25);
}

.footer {
  width: 100%;
  height: 65px;
  align-items: center;
  display: flex;
  justify-content: center;

  @include mobile {
    height: 100px;
    padding: 20px 1rem;
  }
}

.footerText {
  text-align: center;
}

.footerIcons {
  margin-left: 12px;
}

.slogan {
  font-weight: bold;
}
