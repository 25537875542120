@import '../styles/core';

.headerContainer {
  background-color: #262626;
  width: 100%;
  border-bottom: 4px solid $red;
  color: $white;
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
}

.noPadding {
  padding: 0 !important;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  height: 65px;

  @include mobile {
    padding: 20px 1rem;
  }
}

.logo {
  padding-right: 23px;
  display: flex;
  font-weight: 600;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  align-items: center;
  justify-content: center;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-height: 45px;
    margin: 0;
  }
}

.navigation {
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;

  .closeMobile {
    display: none;
  }

  ul {
    display: flex;
    height: 100%;
    align-items: center;
    flex: 1;
    list-style: none;
    padding: 0;
    justify-content: flex-end;
    margin: 0 1rem 0 0;

    li {
      display: block;
      height: 100%;
      margin: 0;

      a {
        display: flex;
        align-items: center;
        height: 100%;

        font-weight: 600;
        font-size: 0.85rem;
        color: $white;

        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
        padding: 0 1rem;

        &:hover {
          background-color: transparentize($red, 0.23);
          text-decoration: none;
        }

        &.active {
          background-color: $red !important;
        }
      }
    }
  }

  @include mobile {
    z-index: 9999;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $black;
    align-items: flex-start;
    transform: translateX(100%);
    transition: transform 0.2s ease;

    &.open {
      transform: translateX(0);
    }

    .closeMobile {
      display: block;
      position: absolute;
      top: 1.2rem;
      right: 1rem;
      width: 28px;
      height: 28px;
      fill: $white;
    }

    ul {
      flex-direction: column;
      height: initial;
      align-items: flex-start;
      margin: calc(50px + 1rem) 0 0;

      li {
        height: initial;
        width: 100%;

        a {
          padding: 1rem;
        }
      }
    }
  }
}

.search {
  display: flex;
  margin-left: auto;
  align-items: center;
}

.hamburger {
  display: none;

  cursor: pointer;
  width: 40px;
  height: 40px;
  fill: $white;

  @include mobile {
    display: block;
  }
}

.socials {
  //margin-left: auto;
  color: $white;

  svg {
    height: 18px;
    width: 18px;
    margin: 0 6px;

    path {
      stroke-width: 0;

      fill: $white;
    }

    &:last-of-type {
      margin: 0;
    }
  }
}

.logout {
  margin-left: 20px;
  font-weight: 500;
  text-transform: uppercase;
  min-width: 85px;

  a {
    text-align: center;
    display: block;
    background-color: $red;
    border-radius: 3px;
    color: $white;
    padding: 7px 15px;
    font-size: 0.7rem;
    letter-spacing: 0.02rem;

    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

    &:hover {
      text-decoration: none;
      background-color: darken($red, 15%);
    }
  }

  @include mobile {
    display: none;
  }
}
