@import '../styles/core';

$border-radius: 6px;

.featured {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  @include mobile {
    max-width: 320px;
    overflow: hidden;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    color: $black;
    &:hover {
      text-decoration: none;
    }
  }
}

.avatar {
  margin-right: 20px;
}

.name {
  font-weight: 500;
  font-size: 1.1rem;
}

.param {
  font-weight: 600;
  font-size: 0.6rem;
  text-transform: uppercase;
  margin-top: 2px;
  color: $gray;
}

.status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-weight: 600;
  font-size: 0.6rem;
  text-transform: uppercase;
  margin-top: 2px;
  color: $gray;

  svg {
    font-size: 0.85rem;
  }
}

.statContainer {
  margin-left: auto;
}

.stats {
  display: flex;

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 6px;
    color: $white;
  }

  .green {
    background-color: $green;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .red {
    background-color: $red;
  }

  .blue {
    background-color: $blue;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .number {
    font-weight: 600;
    font-size: 0.9rem;
  }
}
