@import '../styles/core';

.container {
  margin: 23px;
}

h2 {
  margin: 0 0 23px;
}

.contacts {
  display: flex;
  align-items: center;
  //font-size: 1rem;

  margin-bottom: 1rem;
  font-weight: 500;

  a {
    color: $gray;

    &:hover {
      text-decoration: underline;
    }
  }

  svg {
    color: $gray;
    height: 22px;
    margin-right: 0.4rem;
    width: 22px;
  }
}
