@import '../styles/core';

.row {
  @include mobile {
    margin: 0;
  }
}

.container {
  margin: 8px 0 24px 0;
}

.fighters {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 20px;
  margin: 10px;

  @include mobile {
    padding: 0;
    max-width: 320px;
  }
}

.recent {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 12px 0 0;

  @include mobile {
    flex-direction: column;
    padding: 0;
    max-width: 350px;
  }
}

.updated {
  @include mobile {
    margin-left: 2px;
    max-width: 350px;
  }
}

.seeAll {
  display: flex;
  margin: 0 0 36px;

  a {
    font-size: 0.65rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.02rem;
    margin-left: auto;
  }
}

.title {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.03rem;
  color: $blue;
}

.events {
  display: flex;
  width: 100%;
  justify-content: center;

  a {
    display: flex;
    width: 100%;
    border-right: 1px solid $gray;

    &:last-child {
      border-right: none;
      padding-right: 0;
    }

    &:hover {
      text-decoration: none;
    }
  }

  @include mobile {
    max-width: 350px;
    overflow: scroll;
    justify-content: normal;
  }

  margin: 8px 0 8px;

  .event {
    display: flex;
    flex: 1;
    margin-right: 24px;
    align-items: center;
    min-width: 70px;
    .description {
      flex: 1;
      color: $black;
      font-weight: 500;
      font-size: 1.1rem;
      text-align: center;

      @include mobile {
        font-size: 0.7rem;
      }

      &:last-child {
        margin-left: 8px;
      }

      small {
        font-size: 0.55rem;
        color: $gray;

        svg {
          height: 0.7rem;
        }
      }

      .date {
        margin-top: 5px;
        font-size: 0.75rem;
        color: $blue;

        @include mobile {
          font-size: 0.55rem;
        }
      }
    }
  }
}

h2 {
  margin: 0 0 40px;

  @include mobile {
    margin: 3rem auto 0;
  }
}
