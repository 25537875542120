@import '../styles/core';

.row {
  @include mobile {
    margin: 0 1rem;
  }
}

$border-radius: 6px;

.profile {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid transparentize($gray, 0.9);
  margin: -40px 0;
  padding: 40px 0;

  @include mobile {
    border: 0;
    margin: 0;
    padding: 0;
  }
}

.fights {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 30px;

  .notes {
    font-size: 0.9rem;
    font-weight: 500;
    color: $blue;
  }

  .location {
    @include mobile {
      display: flex;
    }
  }

  .cell {
    @include mobile {
      padding: 4px !important;
      min-height: 32px;
    }
  }

  .opponent {
    color: $black;
    text-decoration: underline;
  }

  .top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-bottom: 20px;

    @include mobile {
      margin-top: 40px;
      flex-direction: column;
    }

    .topAdmin {
      display: flex;
      flex-direction: row;

      .adminLink {
        margin-left: 4px;
      }
    }

    a {
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      background-color: $red;
      border-radius: 3px;
      color: $white;
      padding: 7px 15px;
      font-size: 0.7rem;
      letter-spacing: 0.02rem;
      margin-left: 4px;

      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;

      &:hover {
        text-decoration: none;
        background-color: darken($red, 15%);
      }
    }
  }

  @include mobile {
    margin: 0;
  }

  h2 {
    margin: 0;

    @include mobile {
      margin: 0;
    }
  }
}

.pagination {
  display: flex;
  margin-top: 20px;

  .pageNumber {
    width: 100%;
    text-align: center;
    text-decoration: underline;
  }

  button {
    display: flex;
    cursor: pointer;
    padding: 0.4rem 0.55rem;

    font-size: 0.9rem;
    border: 1px solid transparentize($blue, 0.7);
    background-color: transparentize($blue, 0.9);
    outline: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    &.disabled {
      background-color: transparent !important;
      pointer-events: none;
      opacity: 0.6;
      border: none;
    }

    &:hover {
      background-color: transparentize($blue, 0.7);
    }

    &.next {
      margin-left: auto;

      svg {
        margin-left: 5px;
        margin-right: 0;
      }
    }

    svg {
      height: 11px;
      width: 11px;
      margin-right: 5px;
    }
  }
}

.timeline {
  margin-top: 20px;
  margin-bottom: 20px;

  @include mobile {
    margin: 0;
  }

  table {
    margin: 0;

    .outcome {
      display: none;

      @media screen and (max-width: 40em) {
        display: initial;
        padding: 2px 5px;
        margin-left: 0.2rem;
      }
    }

    th {
      color: $gray;
      text-transform: uppercase;
      letter-spacing: 0.02rem;
      font-size: 0.8rem;
      text-align: left;
    }

    tr {
      @include mobile {
        border: 0;
        border-bottom: 1px solid $blue;

        &:last-of-type {
          border: 0;
        }
      }

      td > div {
        @include mobile {
          left: 0 !important;
        }
      }
    }

    .report {
      button {
        background-color: Transparent;
        background-repeat: no-repeat;
        cursor: pointer;
        border: none;
        overflow: hidden;
        outline: none;

        svg {
          color: red !important;
          margin: 0 !important;
        }
      }
    }

    .admin {
      button {
        border: 1px solid $blue;
        border-radius: $border-radius;
        padding: 2px 3px;
        color: $red;
        cursor: pointer;

        &:first-of-type {
          margin-right: 0.3rem;
        }

        svg {
          margin: 0 !important;
        }
      }
    }

    svg {
      color: $blue;
      height: 14px;
      width: 14px;
      margin-bottom: -1px;
      margin-right: 1px;
      path {
        stroke-width: 2px !important;
      }
    }
  }

  .badge {
    background-color: transparent;
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
    border-radius: 2px;
    padding: 3px 6px;
    color: $white;
    font-weight: 500;
    max-width: 64px;

    &.win {
      background-color: $green;
    }

    &.loss {
      background-color: $red;
    }

    &.draw {
      background-color: #7b9ea8;
    }

    &.exh {
      background-color: $white;
      color: #7b9ea8;
      border-color: #7b9ea8;
    }
  }

  .resultsAtTheTime {
    display: flex;

    .result {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 6px;
      color: $white;
    }

    .green {
      background-color: $green;
      border-top-left-radius: $border-radius;
      border-bottom-left-radius: $border-radius;
    }

    .red {
      background-color: $red;
    }

    .blue {
      background-color: $blue;
      border-top-right-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
    }

    .number {
      font-weight: 600;
      font-size: 0.9rem;
    }
  }
}

.avatar {
  width: 120px;
  height: 120px;
  border: 3px solid $red;
  border-radius: 50%;
  background-position: 50% 5%;
  background-size: cover;
  background-repeat: no-repeat;
}

.name {
  font-size: 1.4rem;
  font-weight: 500;
  color: $black;
  margin-top: 20px;
}

.gender {
  margin-top: 20px;
  font-weight: 600;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 0.02rem;
  color: $gray;
  @include mobile {
    margin-top: 8px !important;
  }
}

.nickName {
  margin-top: 5px;
  font-weight: 500;
  font-size: 0.95rem;
  color: $gray;
}

.status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1rem;
  font-weight: 400;
  color: $black;
  margin-top: 10px;
  @include mobile {
    margin-top: 6px !important;
  }
}

.division {
  display: flex;
  font-size: 1rem;
  font-weight: 400;
  color: $black;
  margin-top: 10px;

  @include mobile {
    margin-top: 6px !important;
  }

  .flag {
    align-self: center;
    width: 2rem;
    margin: 0;
    padding: 0;
    margin-left: 0.2rem;
  }
}

.results {
  display: flex;
  width: 200px;
  justify-content: center;
  margin-top: 20px;
  @include mobile {
    margin-top: 6px !important;
  }

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 20px;
    color: $white;
  }

  .green {
    background-color: $green;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .red {
    background-color: $red;
  }

  .blue {
    background-color: $blue;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .number {
    font-weight: 600;
    font-size: 1.1rem;
  }

  .label {
    font-weight: 300;
    font-size: 0.7rem;
  }
}

.actions {
  display: flex;
  width: 100%;

  > div {
    flex: 1;
    align-items: center;
    justify-content: center;

    button {
      max-width: 210px;
    }
  }
}

.stats {
  flex: 1;
  margin-top: 20px;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include mobile {
    margin-top: 6px !important;
  }

  .separator {
    height: 35px;
    border-right: 2px solid transparentize($gray, 0.7);
  }

  .box {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;

    .figure {
      display: flex;
      font-size: 0.9rem;
      font-weight: 500;
      text-transform: capitalize;
      justify-content: center;
      align-items: center;
    }

    .description {
      margin-top: 7px;
      text-transform: lowercase;
      color: $gray;
      font-size: 0.8rem;
      font-weight: 400;
      justify-content: center;
      align-items: center;
    }
  }
}
