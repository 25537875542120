@import '../../styles/core';

.modal {
  padding: 50px 0 50px 50px;
  width: 50%;

  @include mobile {
    margin: 0;
    width: 100%;
    padding: 20px;
  }
}

.error {
  color: #f92300;
  font-weight: bold;
  padding-top: 4px;
}

.reportModal {
  padding: 25px;
  width: 50%;

  @include mobile {
    margin: 0;
    width: 100%;
    padding: 20px;
  }

  .search {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    width: 100%;

    .searchButton {
      margin-top: 20px;
    }
  }

  .report {
    .details {
      display: flex;
      flex-direction: row;
    }

    .inputName {
      max-width: 250px;

      &:first-of-type {
        margin-right: 12px;
      }
    }

    .inputMessage {
    }
  }
}

.confirm {
  display: flex;
  padding: 40px;

  > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;

    .icon {
      color: $red;

      width: 80px;
    }

    .buttons {
      display: flex;
      width: 100%;
      justify-content: center;

      > div {
        margin-right: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }

      button {
        min-width: 140px;
      }
    }
  }

  width: 40%;
}

.overlay {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.3);
}
